// Wait for the DOM to be ready
let elements = document.querySelectorAll('.skills-list-btn');

// Check if any elements are found
if (elements.length > 0) {
  // Attach a click event listener to each element
  elements.forEach(el => {
    el.addEventListener('click', () => {
      // Extract the data-menu attribute value
      let dataTypeValue = el.getAttribute('data-menu');

      // Remove "active" class from all buttons
      removeAllActiveClasses();

      // Add "active" class to the clicked button
      el.classList.add('active');

      // Hide all menus
      hideAllMenus();

      // Show the menu with the corresponding data-menu value
      showMenu(dataTypeValue);
    });
  });
} else {
  console.error('Elements with class "skills-list-btn" not found.');
}

function removeAllActiveClasses() {
  // Remove "active" class from all buttons by selecting them with a common class
  let allButtons = document.querySelectorAll('.skills-list-btn');
  allButtons.forEach(button => {
    button.classList.remove('active');
  });
}

function hideAllMenus() {
  // Hide all menus by selecting them with a common class and hiding them
  let allMenus = document.querySelectorAll('.skills__items');
  allMenus.forEach(menu => {
    menu.style.display = 'none';
  });
}

function showMenu(dataTypeValue) {
  // Show the menu with the specified data-menu value
  let menuToShow = document.querySelector('.skills__items[data-type="' + dataTypeValue + '"]');
  if (menuToShow) {
    menuToShow.style.display = 'flex';
  } else {
    console.error('Menu with data-type "' + dataTypeValue + '" not found.');
  }
}
