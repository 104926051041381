import domReady from '@roots/sage/client/dom-ready';

// Import Custom Js
import './common.js'


/**
 * Application entrypoint
 */
domReady(async () => {

});
